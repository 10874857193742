@import '_default';
@include font('BBold', 'bebasneue-bold');
@include font('BRegular', 'bebasneue-regular');
@include font('BRegular2', 'bebasneue-0');
@include font('MRegular', 'mangal');
@include font('MBold', 'mangalb');

:root {
    --promaryColor: #04438c;
    --red: #ed1c24;
    --textColor: #58595b;
    --bgFontColor: #f1f2f2;
}
$promaryColor: #04438c;
$red: #ed1c24;
$lightGray: rgb(241, 242, 242);
$gray: #58595b;
$sectionSpace: 80px;

%wrap-width {
    width: 80%;
    margin: 0 auto;
    @include media(420px) {
        width: 90%;
    }
}

// gird
.g-section {
    padding: ($sectionSpace * 2) 0 $sectionSpace !important;
    @include media(420px) {
        padding: ($sectionSpace / 2) 30px !important;
    }

    .g-section-img {
        position: relative;
        margin: {
            top: -30%;
        }
        text-align: center;
        @include media(420px) {
            margin: {
                top: 0;
            }
        }

        img {
            max-width: (800px * .6);
        }
    }
}
.g-product {
    $primaryWidth: 70%;
    #primary {
        float: right;
        width: $primaryWidth;
        @include media(800px) {
            width: 100%;
        }

        .woocommerce-result-count,
        .woocommerce-ordering,
        .woocommerce-products-header {
            @extend %none;
        }
    }
    #secondary {
        float: left;
        width: 100% - $primaryWidth - 5;
        @include media(800px) {
            width: 100%;
        }
    }
}


// moduel
.m-address {
    padding: {
        left: 0;
    }
    list-style: none;

    .m-address-item {
        position: relative;
        
        &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: {
                right: 10px;
            }
            background: url('../images/spires.png') no-repeat;
            background-position-x: left;
            vertical-align: middle;
        }

        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                &:before {

                    @if $i == 1 {
                        background-position: left top;

                    } @else if $i == 2 {
                        background-position: left -25px;

                    } @else {
                        background-position: left -45px;
                    }
                }
            }
        }
    }
}
.m-banner {
    position: relative;
    border: {
        bottom: 16px solid var(--promaryColor);
    }

    .m-banner-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: {
            bottom: 30px;
        }
        text-align: center;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100px;
            height: 1px;
            background-color: var(--promaryColor);
            animation: opac 1s cubic-bezier(.2, .8, .6, .4) both;
        }
    }

    .m-banner-title,
    .m-banner-text {
        color: var(--promaryColor);
        font-family: "MBold", serif;
        line-height: 1;
    }

    .m-banner-title {
        font-size: 48px;
        animation: ltr 1s cubic-bezier(.8, .5, .2, .5) both;
        @include media(800px) {
            font-size: 32px;
        }
        @include media(420px) {
            font-size: 24px;
        }
    }
    
    .m-banner-text {
        font-size: 24px;
        animation: rtl 1s cubic-bezier(.8, .5, .2, .5) both;
        @include media(800px) {
            font-size: 20px;
        }
        @include media(420px) {
            font-size: 16px;
        }
    }
}
.m-text {
    @extend %wrap-width;
    text-align: center;

    
    p + p {
        margin: {
            top: 15px;
        }
    }
}
.m-bg {
    position: relative;
    
    .m-bg-text {
        position: relative;
        z-index: 2;
    }

    .m-bg-font {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) scale(1, .75);
        width: 100%;
        text-align: center;
        font: 230px/.8 "BRegular2", serif;
        color: $lightGray;
        clear: both;
        cursor: default;
        @include media(1024px) {
            font-size: 180px;
        }
        @include media(800px) {
            font-size: 140px;
        }
        @include media(420px) {
            display: none;
        }
    }
}
.m-list {
    @extend %wrap-width;
    padding: {
        top: 50px;
    }
    @include media(420px) {
        padding: {
            top: 20px;
        }
    }

    .m-list-item {
        position: relative;
        @include media(420px) {
            + .m-list-item {
                margin: {
                    top: 20px;
                }
            }
        }

        &:hover {
            &:before {
                background-color: $promaryColor;
            }

            .m-list-text {
                color: $promaryColor;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: $gray;
        }
    }

    .m-list-text {
        padding: 0 10px;
    }
}
.m-form {
    .wpcf7 {
        %input {
            width: 100%;
            margin: {
                top: 8px;
            }
            border: none;
            background-color: $lightGray;
            font: 18px "MRegular", serif;
            color: $promaryColor;
            @include media(420px) {
                font-size: 14px;
            }

            &:focus {
                outline: 0;
            }

            &::placeholder {
                color: $promaryColor;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $promaryColor;
            }

            &::-ms-input-placeholder {
                color: $promaryColor;
            }
        }

        input {
            &[name="your-name"] {
                margin: {
                    top: 0;
                }
            }

            &[type="text"],
            &[type="email"],
            &[type="tel"] {
                @extend %input;
                @include media(420px) {
                    height: 40px;
                }
            }

            &[type="reset"],
            &[type="submit"] {
                width: 40%;
                max-width: 220px;
                padding: 4px 0;
                border-radius: 6px;
                text-align: center;
                font: bold 20px "BRegular", serif;
                border: none;
            }

            &[type="submit"] {
                margin: {
                    left: 10px;
                }
                background-color: var(--red);

                &:hover {
                    color: var(--red);
                    background-color: transparent;
                }
            }
        }

        textarea {
            @extend %input;
            height: 100px;
            resize: none;
        }
    }
}
.m-adorn {
    position: relative;

    .m-adorn-img {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-36%);
        max-width: 200px;
        @include media(1024px) {
            max-width: 160px;
            transform: translateY(-17%);
        }
        @include media(420px) {
            display: none;
        }
    }
}

// unit
.u-title {
    padding: {
        bottom: 20px;
    }
    font: 24px/1.2 "MBold", sans-serif;
    color: $promaryColor;
    @include media(800px) {
        font-size: 18px;
    }
}
.u-btn {
    margin: {
        top: 20px;
    }

    .vc_btn3 {
        padding: 0 8px;
        background-color: $lightGray !important;
        border: none;
        font-family: "MRegular", serif !important;
        font-weight: normal;
        color: $promaryColor !important;

        &:hover {
            border: none;
            background-color: $promaryColor !important;
            color: white !important;
        }
    }
}
.u-btn-2 {
    display: inline-block;
    padding: 4px 8px;
    background-color: $lightGray;
    border-radius: 7px;
    font: 14px "MRegular", serif;
    color: $promaryColor;

    &:hover {
        background-color: darken($lightGray, 10%);
        color: $promaryColor;
        transition: background-color .4s cubic-bezier(.2, .8, .7, .6);
    }
}

// function
.f-section-space {
    padding: $sectionSpace 0 !important;
    @include media(420px) {
        padding: ($sectionSpace / 2) 15px !important;
    }
}

// woocommerce
.widget_product_categories {
    .widget-title {
        padding: 10px 20px;
        background-color: $promaryColor;
        font: 24px "MBold", serif;
        color: rgb(255, 255, 255) !important;
    }

    .cat-item {
        padding: 2px 0 !important;

        + .cat-item {
            border: {
                top: 2px dotted $promaryColor;
            }
        }
        
        &.cat-parent {
            > a {
                position: relative;
            }

            &.current-cat,
            &.current-cat-parent {
                > a {
                    &:after {
                        visibility: hidden;
                    }
                }
            }
        }

        &.current-cat,
        &.current-cat-parent {
            > a {
                .count {
                    display: none;
                }
            }

            > .children {
                display: block;
            }
        }

        &.current-cat {
            > a {
                color: $promaryColor !important;

                &:before {
                    content: '';
                    display: inline-block;
                    width: .3em;
                    height: 20px;
                    margin: {
                        right: 0.8em;
                    }
                    background-color: $promaryColor;
                    vertical-align: top;
                }
            }
        }

        > a {
            display: block;
            padding: 10px;
            font-size: 14px;

            &:hover {
                color: $promaryColor !important;
            }

            .count {
                @extend %none;
            }
        }

        .children {
            display: none;
            padding: {
                left: 1em;
            }

            .children {
                padding: {
                    left: 2em;
                }
            }

            .cat-item {
                + .cat-item {
                    border: {
                        top: none;
                    }
                }
            }

            a {
                padding: {
                    left: 10px;
                }
            }
        }
    }
}


// foot and head
.header-clone {
    @extend %none;
}
.site-header {
    position: absolute !important;
    top: 10px !important;
    background-color: transparent !important;
    @include media(1024px) {
        &.fixed {
            position: absolute !important;
            @include media(420px) {
                position: static !important;
            }
        }
    }
    @include media(420px) {
        position: static !important;
        padding: 10px 0;
    }

    .head-contact {
        margin: {
            bottom: -10px;
        }
        text-align: right;
        color: var(--red);
        @include media(420px) {
            display: none;
            margin: {
                bottom: 0;
            }
        }

        span {
            display: inline-block;

            + span {
                margin: {
                    left: 20px;
                }
            }

            &:before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('../images/spires.png') no-repeat;
                background-position-x: left;
                vertical-align: middle;
            }

            @for $i from 1 through 2 {
                &:nth-child(#{$i}) {
                    &:before {

                        @if $i == 1 {
                            background-position-y: -24px;
                            
                        } @else {
                            background-position-y: -46px;
                        }
                    }
                }
            }
        }
    }

    .mainnav {
        position: relative;
        width: 100%;
        padding: 15px 0;
        background-color: var(--promaryColor);

        .nav-bg {
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);
            width: 60px;
            height: 120%;
            background-color: var(--red);
            transition: left .2s cubic-bezier(1, 1, 1, 1), 
            width .3s cubic-bezier(0, .2, .4, 1);
            @include media(1024px) {
                display: none;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 10%;
                height: 100%;
                background-image: linear-gradient(to bottom, darken($red, 10%) 9%, var(--promaryColor) 0, var(--promaryColor) 91%, darken($red, 10%) 0);
            }

            &:before {
                left: 0;
                transform: translateX(-100%);
                clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
            }

            &:after {
                left: 100%;
                clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
            }
        }

        .menu-nav-container {
            position: relative;
            z-index: 2;
        }

        .menu-item {
            width: (100% / 6);
            padding: 0 !important;
            text-align: center;
            @include media(1024px) {
                width: 100%;
            }

            &.current_page_item,
            &.current-menu-item {
                > a {
                    font-family: "MBold", serif !important;
                }
            }
            
            > a {
                float: none !important;
                display: inline-block !important;
                padding: 0 10px;
                text-transform: uppercase;
            }
        }
    }
    @include media(1024px) {
        .btn-menu {
            margin: {
                top: 30px;
            }
            color: var(--red);
            @include media(420px) {
                margin: {
                    top: 10px;
                    right: 0;
                }
            }
        }

        #mainnav-mobi {
            top: 50px;
            @include media(420px) {
                top: 30px;
            }
        }
    }
    @include media(420px) {
        .site-logo {
            max-height: 50px;
        }
    }
}
.footer-widgets {
    padding: 30px 0;
    border: {
        top: 14px solid var(--red);
    }
    font-size: 14px;
    @include media(420px) {
        border: {
            width: 6px;
        }
    }

    #media_image-2 {
        text-align: center;

        img {
            max-width: 200px !important;
        }
    }

    .widget_media_image {
        margin: {
            top: 18%;
        }
        @include media(420px) {
            margin: {
                top: 0;
                bottom: 20px !important;
            }
            text-align: center;
        }
    }

    .widget-title {
        font-size: 14px;
    }

    #text-2 {
        .widget-title {
            font-weight: normal;
        }
    }

    .menu {
        .menu-item {
            padding: 4px 0;

            > a {
                text-transform: capitalize;
            }
        }

        a {
            &:hover {
                color: var(--red) !important;
            }
        }
    }

    .widget:last-child {
        margin: {
            bottom: 0;
        }
    }

    .widget_custom_html {
        padding: {
            top: 0;
        }
    }

    .nav_menu-2 {
        padding: {
            left: 50px;
        }
        @include media(800px) {
            padding: {
                left: 20px;
            }
        }
        @include media(420px) {
            padding: {
                left: 0;
            }
        }
    }
}
.site-footer {
    .copyright {
        float: left;
    }
    .company {
        float: right;
    }

    .company,
    .copyright {
        font-size: 14px;
        line-height: 1.2;
        color: var(--promaryColor);
        @include media(420px) {
            float: none;
            display: block;
            text-align: center;
        }
    }

}

// home
.h-about {
    @include media(420px) {
        padding: {
            left: 15px !important;
            right: 15px !important;
        }
    }

    .h-about-title {
        margin: {
            top: -270px;
        }
        @include media(800px) {
            max-width: 80%;
            margin: -200px auto 0;
        }
        @include media(420px) {
            margin: -80px auto 0;
        }
    }

    .main > .wpb_column {
        position: relative;
        padding: {
            bottom: 30px;
        }
        margin: {
            bottom: 50px;
        }
        @include media(420px) {
            padding: {
                bottom: 10px;
            }
            margin: {
                bottom: 30px;
            } 
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100px;
            height: 6px;
            background-color: $promaryColor;
            clear: both;
            @include media(420px) {
                right: 50%;
                transform: translateX(50%);
            }
        }
    }
}
.h-products {

    .wpb-woo-cat-slider {
        margin: {
            top: 10px;
        }
        @include media(420px) {
            margin: {
                top: 0;
            }
        }

        img {
            width: 100% !important;
        }

        .owl-stage-outer {
            z-index: 2;
        }
    }

    .wpb-woo-cat-item {
        padding: 0 !important;
        background-color: #58595b !important;

        &:hover {
            background-color: var(--red) !important;
            transition: background-color .3s cubic-bezier(.5, .9, .2, .3);
        }
        
        h3 {
            font: 22px "MBold", serif;
            color: rgb(255, 255, 255);
        }

        .btn {
            @extend %none;
            margin: {
                top: 0 !important;
            }
            padding: 0 !important;
        }

    }

    .owl-nav {
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        .owl-prev,
        .owl-next {
            width: 30px;
            height: 30px;
            margin: {
                top: 0 !important;
                bottom: 0 !important;
                left: 0 !important;
            }
            border: 1px solid white;
            background-color: transparent !important;
            border-radius: 50% !important;
        }

        .owl-prev {
            float: left;
            transform: translateX(-120%);
            @include media(420px) {
                transform: translateX(-90%);
            }

            > .fa {
                margin: {
                    top: 3px;
                    left: -2px;
                }
            }
        }

        .owl-next {
            float: right;
            transform: translateX(120%);
            @include media(420px) {
                transform: translateX(90%);
            }
        }
    }
}
.h-certificate {
    .white {
        border: none !important;
        background-color: transparent !important;
    }

    .owl-nav {
        > .owl-prev {
            margin: {
                left: -50px;
            }
            background-color: transparent !important;
        }
        
        .owl-next {
            margin: {
                right: -50px;
            }
            background-color: transparent !important;
        }
    }
    
    .owl-item {
        .sa_hover_container {
            padding: 0 !important;
            min-height: auto !important;
        }

        .img-wrap,
        .text-wrap {
            float: left;
            @include media(420px) {
                float: none;
            }
        }

        .img-wrap {
            width: 45%;
            overflow: hidden;
            @include media(420px) {
                width: 100%;
            }

            img {
                width: 48%;

                &:first-child {
                    float: left;
                }

                &:last-child {
                    float: right;
                }
            }
        }

        .text-wrap {
            width: 55%;
            @include media(420px) {
                width: 100%;
            }

            .text {
                padding: {
                    left: 60px;
                }
                margin: {
                    top: 34%;
                }
                @include media(800px) {
                    padding: {
                        left: 10px;
                    }
                }
                @include media(420px) {
                    padding: {
                        left: 0;
                    }
                    margin: {
                        top: 20px;
                    }
                }

                + div {
                    left: 45%;
                    z-index: -1;
                    color: $lightGray;
                }
            }
        }
    }
}
.h-form {
    padding: 70px 0;
    @include media(420px) {
        padding: 30px 0;
    }

    .title {
        font: 220px/.8 "BBold", serif;
        color: $lightGray;
        cursor: default;
        @include media(420px) {
            margin: {
                top: 20px;
            }
            font-size: 140px;
        }
        @include media(400px) {
            font-size: 120px;
        }
    }

    .col-md-7 {
        padding: {
            left: 5%;
        }
        @include media(420px) {
            padding: {
                left: 0;
            }
        }
    }

    @include media(420px) {
        .wpb_row,
        .vc_column-inner {
            padding: {
                left: 0;
                right: 0;
            }
        }    
    }
}

// about
.a-business {
    position: relative;

    .title {
        font: 16px "MRegular", serif;
    }

    .list {
        margin: {
            top: 50px;
        }

        .sub-title {
            margin: {
                top: 10px;
            }
            font: 14px "MBold", serif;
        }

        .list-item {
            img {
                max-width: 120px;
            }
            
            @include media(420px) {
                margin: {
                    top: 20px;
                }
            }
        }
    }

    .text {
        position: relative;
        z-index: 2;
    }

    .bg-font {
        position: absolute;
        top: 60%;
        left: 0;
        transform: translateY(-50%) scale(1, .75);
        width: 100%;
        text-align: center;
        font: 400px/.8 "BBold", serif;
        color: rgba($lightGray, .05);
        @include media(1024px) {
            font-size: 350px;
        }
        @include media(800px) {
            font-size: 260px;
        }
        @include media(800px) {
            display: none;
        }
    }
}
.a-img {
    padding: 40px 0 20px;
}
.a-brands {
    margin: {
        top: 50px;
    }
}

// awards
.a-awards {
    margin: {
        top: 30px;
    }

    + .a-awards {
        .title {
            padding: {
                top: 30px;
            }
            border: {
                top: 1px solid $gray;
            }
        }
    }

    .title {
        font: 18px/1.2 "MBold", serif;
        @include media(420px) {
            font-size: 16px;
        }
    }

    .list {
        margin: {
            top: 40px;
        }
        padding: 0 15px;
        @include media(420px) {
            margin: {
                top: 20px;
            }

            .list-item + .list-item {
                margin: {
                    top: 20px;
                }
            }
        }

        + .list {
            margin: {
                top: 60px;
            }
            @include media(420px) {
                margin: {
                    top: 20px;
                }
            }
        }

        .sub-title {
            margin: {
                top: 10px;
            }
            font: 18px/1.2 "MRegular", serif;
            @include media(800px) {
                font-size: 16px;

                br {
                    display: none;
                }
            }
        }
    }
}

// catalogue
.c-catalogue {

    .vc_grid {
        @include media(420px) {
            padding: {
                left: 15px !important;
                right: 15px !important;
            }
        }
        
        .vc_grid-item {
            width: (100% / 5);
            padding: {
                bottom: 60px !important;
            }
            @include media(800px) {
                width: (100% / 3);
            }
            @include media(420px) {
                float: left;
                width: (100% / 2);
            }
            @include media(400px) {
                float: none;
                width: 100%;
            }
        }

        .vc_gitem-post-data-source-post_excerpt {
            @extend %none;
        }

        .vc-gitem-zone-height-mode-auto {
            &:before {
                display: none !important;
            }
            
            .vc_gitem-zone-img {
                display: block !important;
            }
        }

        .vc_gitem-zone-mini {
            background-color: white !important;
        }
        
        .vc_gitem-post-data-source-post_title {
            margin: {
                bottom: 0 !important;
            }

            > h4 {
                text-align: center !important;
                font: 18px/1.2 "MRegular", serif;
                color: $gray;
            }
        }

        .vc_gitem-link.vc-zone-link {
            &:before {
                content: 'read More';
                position: absolute;
                width: 100%;
                height: 100%;
                padding-top: 65%;
                background-color: rgba($promaryColor, .9);
                text-align: center;
                text-transform: uppercase;
                font: 18px "MRegular", serif;
                color: white;
                transform: translateY(-100%);
            }

            &:hover {
                &:before {
                    transform: translateY(0);
                    transition: transform .4s cubic-bezier(1, 1, 1, 1);
                }
            }
        }

        .vc_btn3-container {
            @extend %none;
        }
    }

    .list {
        + .list {
            margin: {
                top: 60px;
            }
            @include media(800px) {
                margin: {
                    top: 40px;
                }
            }
            @include media(420px) {
                margin: {
                    top: 0;
                }
            }
        }
    }

    @include media(420px) {
        .vc_col-sm-3 {
            margin: {
                top: 30px;
            }
        }
    }

    .item {
        .icon-pdf {
            display: block;
            width: 100%;
            height: 30px;
            margin: {
                top: 1em;
            }
            background: url('../images/icon-pdf.png') center center no-repeat;
        }

        .vc_single_image-wrapper {
            position: relative;
            overflow: hidden;

            &:before {
                content: 'VIEW PDF';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: {
                    top: 0;
                }
                background-color: rgba($promaryColor, .9);
                color: white;
                font: 18px "MRegular", serif;
                transform: translateY(100%);
            }

            &:hover {
                &:before {
                    padding: {
                        top: 65%;
                    }
                    transform: translateY(0);
                    transition: transform .4s cubic-bezier(1, 1, 1, 1),
                    padding-top .3s cubic-bezier(.2, .3, .6, .9) .2s;
                }
            }
        }
    }

    .sub-title {
        margin: {
            top: 10px;
        }
        font: 18px/1.2 "MRegular", serif;
    }
}

// contact
.c-contact {
    overflow: visible !important;
    @include media(420px) {
        padding: {
            left: 0 !important;
            right: 0 !important;
        }
    }

    .title {
        font: 38px/1.2 "MBold", serif;
        color: $promaryColor;
        @include media(800px) {
            margin: {
                top: 16%;
            }
            font-size: 22px;
        }
        @include media(420px) {
            margin: {
                top: 20px;
            }
        }
    }

    .address {
        margin: 60px 0 40px;
        font: 22px/1.2 "MRegular", serif;
        color: $promaryColor;
        @include media(800px) {
            font-size: 16px;
        }
        @include media(420px) {
            margin: 30px 0 20px;
        }
    }

    .address-list {
        margin: {
            bottom: 40px;
        }
        color: $promaryColor;
    }

    .address-item {
        &:before {
            background-image: url('../images/spires-2.png');
        }

        @for $i from 2 through 3 {
            &:nth-child(#{$i}) {
                &:before {

                    @if $i == 2 {
                        background-position-y: -30px;
                    } @else {
                        background-position-y: -57px;
                    }
                }
            }
        }
    }

    .wrap {
        position: relative;
        z-index: 2;
        @include media(420px) {
            padding: {
                left: 0 !important;
                right: 0 !important;
            }
        }
    }

    .bg-font {
        position: absolute;
        top: 0;
        left: 45%;
        margin: {
            top: -90px;
        }
        font: bold 280px/.8 "BRegular", serif;
        color: rgb(252,252,252);
        cursor: default;
        transform: scale(1, .75);
        @include media(1440px) {
            left: 45%;
        }
        @include media(1024px) {
            font-size: 220px;
        }
        @include media(800px) {
            font-size: 160px;
        }
        @include media(420px) {
            display: none;
        }
    }
    @include media(420px) {
        .vc_column-inner {
            padding: {
                left: 0 !important;
                right: 0 !important;
            }
        }
    }
}

// product
.p-category {
    .products {
        display: flex;
        flex-wrap: wrap;
    }

    .woocommerce-breadcrumb {
        border: {
            bottom: none;
        }
        text-align: right;
        font: 14px "MBold", serif;
        color: $promaryColor;

        > a {
            font-family: "MRegular", serif !important;
            color: $gray !important;

            &:hover {
                text-decoration: underline;
                color: $promaryColor !important;
            }
        }
    }

    .product-category,
    .product {
        width: (100% / 3) !important;
        margin: {
            right: 0 !important;
            bottom: 24px !important;
        }
        padding: 0 20px !important;
        @include media(800px) {
            width: (100% / 2) !important;
        }
        @include media(400px) {
            width: 100% !important;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            height: 90%;
            background-color: $gray;
        }

        > a,
        .woocommerce-LoopProduct-link {
            display: block;
            position: relative;
            height: 100%;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $gray;
            }
        }

        img,
        .attachment-woocommerce_thumbnail {
            max-width: 90%;

            margin: {
                bottom: 0 !important;
            }
        }

        .button {
            @extend %none;
        }

        .woocommerce-loop-category__title,
        .woocommerce-loop-product__title {
            height: 54.4px;
            text-align: center;
            @include media(400px) {
                height: auto;
            }

            .count {
                @extend %none;
            }
        }

        .woocommerce-loop-category__title {
            font: 18px/1.2 "MRegular", serif;
            color: $gray;
        }

        .woocommerce-loop-product__title {
            font: 18px/1.2 "MBold", serif;
            color: $promaryColor;
        }

        .detail {
            padding: {
                top: 1em;
                bottom: 1.5em;
            }
            text-align: left;
            font-size: 12px;
            overflow: hidden;
            line-height: 1.4;

            .col-sm-8 {
                width: 100% !important;
            }

            ul {
                margin: {
                    bottom: 0 !important;
                }
            }

            strong {
                display: inline-block !important;
                font-weight: normal;
                font-size: 1.4em;
            }
        }

        &:hover {
            > a {
                &:after {
                    height: 2px;
                    background-color: $promaryColor;
                }
            }

            .woocommerce-loop-category__title {
                font-family: "MBold", serif;
                color: $promaryColor;
            }
        }
    }
}

// animation
@keyframes opac {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes ltr {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    
    80% {
        opacity: 1;
    }
    
    to {
        transform: translateX(0);
    }
}
@keyframes rtl {
    from {
        opacity: 0;
        transform: translateX(50%);
    }
    
    80% {
        opacity: 1;
    }
    
    to {
        transform: translateX(0);
    }
}